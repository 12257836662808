import React, { ReactNode, useEffect } from 'react';
import {useNotificationActions} from "../../hooks/useNotification";
import {notifyError} from "../../utils/notification";


const ErrorBounded = ({ children, error }: { children: ReactNode; error: any }) => {
    const { addNotification } = useNotificationActions();

    useEffect(() => {
        notifyError(error, addNotification);
    }, []);

    return <>{children}</>;
};

type ErrorBoundaryState = { hasError: boolean; error: any };

// eslint-disable-next-line @typescript-eslint/ban-types
type ErrorBoundaryProps = {
    children: any
};

class ErrorBoundary
    extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps | Readonly<ErrorBoundaryProps>) {
        super(props);
        this.state = { hasError: false, error: '' };
    }

    static getDerivedStateFromError(error: any): ErrorBoundaryState {
        return { hasError: true, error };
    }

    render(): ReactNode {
        if (this.state.hasError) {
            return (
                <ErrorBounded
                    error={process.env.NODE_ENV === 'development' ? this.state.error : ''}>
                    {this.props.children}
                </ErrorBounded>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
