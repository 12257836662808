import apiCalls from "../utils/fetch";
import {LoginResponse} from "../models/LoginResponse";
import {Category} from "../models/Category";

export const categories = (): any => apiCalls.get<Category[]>({
    endpoint: 'category/findAll',
    headers: {
        'Content-Type': 'application/json',
    },
});
