import { useContext } from 'react';
import {
    Notification,
    NotificationActionsContext,
    NotificationValueContext
} from "../components/notification/notificationContext";

export const useNotificationActions = (): any => useContext(NotificationActionsContext);

export const useNotificationValue = (): Notification | null => useContext(NotificationValueContext);
