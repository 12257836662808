import {Box, Card, CardContent, CardMedia, Chip, Typography} from "@mui/material";
import React from "react";
import {MovieResultVote} from "../../models/MovieResponse";
import * as _ from "lodash";

export const RowResultCardMovie: React.FC<{movie:MovieResultVote,index:number,withPosition?:boolean,watchRegion:string}> = ({movie,index,withPosition,watchRegion}) => {
    return (
        <Card sx={{ display: 'flex', width:350 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width:250 }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    {withPosition ? <Chip label={`${index+1}`} /> : null}
                    <Typography component="div" variant="h6">
                        {movie.title}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        Result vote: {movie?.resultVote}
                    </Typography>
                    { movie.watchProviders?.results[watchRegion]?.flatrate?.length ?
                        <>
                            <Box sx={{display: 'flex', overflowX: 'auto', width: 220, height: 40, flexDirection: 'row'}}>
                                {_.uniq(movie.watchProviders?.results[watchRegion].flatrate)?.map((provider) => {
                                    return (
                                        <Chip label={provider.provider_name} variant='outlined'/>
                                    );
                                })}
                            </Box>
                        </> : null
                    }
                </CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                </Box>
            </Box>
            <CardMedia
                component="img"
                sx={{ width: 100 }}
                image={`https://image.tmdb.org/t/p/w200${movie.poster_path}`}
                alt="poster"
            />
        </Card>
    )
}
