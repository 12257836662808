import {
    Box,
    Grid, IconButton,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {PartyPublicInfo} from "../models/PartyPublicInfo";
import filmatchBE from "../service/FilmatchBE";
import {notifyError} from "../utils/notification";
import {useNotificationActions} from "../hooks/useNotification";
import {useSession, useSessionAction} from "../hooks/useSession";
import {RowParty} from "../components/partCard/rowParty";
import AddIcon from '@mui/icons-material/Add';
import {RowPartySkeleton} from "../components/partCard/rowPartySkeleton";
import generateRandomString from "../utils/generateRandomString";

export const HomePage: React.FC = () => {
    const { addNotification } = useNotificationActions();
    const session = useSession();
    const setSession = useSessionAction();
    const navigate = useNavigate();
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [parties, setParties] = React.useState<PartyPublicInfo[]>([]);


    useEffect(() => {
        (async () => {
            try {
                if(!session?.token){
                    const response= await filmatchBE.signup(generateRandomString(6), generateRandomString(6),generateRandomString(6));
                    window.localStorage.setItem('token', response.token);
                    window.localStorage.setItem('user', JSON.stringify(response.user));
                    setSession({
                        user: response.user,
                        token: response.token,
                    });
                }
            } catch (e:any) {
                if(e.res?.status === 401){
                    // notifyError('Forbidden Request', addNotification)
                    // setSession(null);
                    // navigate('/signin')
                } else {
                    window.location.reload();
                }
            }
        })();
        (async () => {
            try {
                setLoadingSearch(true);
                setParties(await filmatchBE.recentParty(session?.user.username ?? ''));
                setLoadingSearch(false);
            } catch (e:any) {
                if(e.res.status === 401){
                    // notifyError('Forbidden Request', addNotification)
                    // setSession(null);
                    // navigate('/signin')
                } else {
                    notifyError('Errore nel caricamento del party', addNotification)
                }
            }
        })();
        if(session === null){
            navigate('/signin');
        }
    }, []);
    return (
        <Grid
            container
            spacing={1}
            alignItems="center"
            direction="column"
            justifyContent="start"
            mt={6}
        >
            <Grid item>
                <Typography variant="h6">
                    Recent Party
                </Typography>
            </Grid>
            <Grid item sx={{overflow:'auto', height:700}}>
                {
                    loadingSearch ?[1,2,3].map((m) => {
                        return (
                            <Box p={1}>
                                <RowPartySkeleton/>
                            </Box>
                        )
                    })
                        :
                        <>
                        {parties?.length ? parties?.slice(0,3)?.map((party,index) => {
                                return (
                                    <Box p={1}>
                                        <RowParty party={party} index={index}/>
                                    </Box>

                                )
                            }) :
                            <Grid container
                                  alignItems="center"
                                  direction="column"
                                  mt={'50%'}
                            >
                                <Grid item>
                                        <Typography variant="h6" color={'text.secondary'}>
                                            No recent party
                                        </Typography>
                                </Grid>
                                <Grid item mt={20}>
                                    <Typography variant="h6" color={'text.secondary'}>
                                        Create new one!
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton size={"large"} onClick={() => navigate('/createParty')}>
                                        <AddIcon fontSize={"inherit"}/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        }
                        </>
                }
            </Grid>
        </Grid>
    )
}
