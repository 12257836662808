import {useNotificationActions} from "../../hooks/useNotification";
import React, {useEffect, useState} from "react";
import filmatchBE from "../../service/FilmatchBE";
import {notifyError} from "../../utils/notification";
import {useSession, useSessionAction} from "../../hooks/useSession";
import {useNavigate} from "react-router-dom";
import {MovieResultVote} from "../../models/MovieResponse";
import {Box, Grid} from "@mui/material";
import {RowResultCardMovie} from "../MovieCard/rowResultCardMovie";

export const ListResultMovie: React.FC<{partyId:string,height?:number,withPosition?:boolean,watchRegion:string}> = ({partyId,height,withPosition,watchRegion}) => {
    const { setSession } = useSessionAction();
    const navigator = useNavigate();
    const { addNotification } = useNotificationActions();
    const [partyResultVote,setPartyResultVote] = useState<MovieResultVote[]>([])

    useEffect(() => {
        (async () => {
            try {
                if(partyId){
                    const partyResultVote = await filmatchBE.getResultParty(partyId);
                    setPartyResultVote(partyResultVote);
                }

            } catch (e:any) {
                if(e.res.status === 401){
                    notifyError('Forbidden Request', addNotification)
                    setSession(null);
                    navigator('/signin')
                } else {
                    notifyError('Errore nel caricamento del party', addNotification)
                }
            }
        })();
    }, []);
    return (
        <Box sx={{overflowY:'auto', height: height ?? 720}}>
            {
                partyResultVote.map((movie,index) => {
                    return (
                        <Grid key={index} item mt={1} mb={1}>
                            <RowResultCardMovie watchRegion={watchRegion} withPosition={withPosition} movie={movie} index={index}/>
                        </Grid>
                    )
                })
            }
        </Box>
    )
}
