import apiCalls from "../utils/fetch";
import {LoginResponse} from "../models/LoginResponse";

export const login = (username: string, password: string): any => apiCalls.post<LoginResponse>({
    endpoint: 'user/signin',
    headers: {
        'Content-Type': 'application/json',
    },
    data: JSON.stringify({ username, password }),
});

export const signup = (username: string, password: string, email?: string, name?: string,country?:string): any => apiCalls.post<LoginResponse>({
    endpoint: 'user/signup',
    headers: {
        'Content-Type': 'application/json',
    },
    data: JSON.stringify({ username, password, email, name,country }),
});
