import {useSession, useSessionAction} from "../hooks/useSession";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {AccessPage} from "./access.page";
import filmatchBE from "../service/FilmatchBE";
import generateRandomString from "../utils/generateRandomString";
import {notifyError} from "../utils/notification";
import {useNotificationActions} from "../hooks/useNotification";

export const JoinPartyPage: React.FC = () => {
    const params = useParams();
    const session = useSession();
    const setSession = useSessionAction();
    const addNotification = useNotificationActions();
    const navigate = useNavigate();
    const [login, setLogin] = useState(false);

    useEffect(() => {
        if(session === null){
            (async () => {
                try {
                        const response= await filmatchBE.signup(generateRandomString(6), generateRandomString(6),generateRandomString(6));
                        window.localStorage.setItem('token', response.token);
                        window.localStorage.setItem('user', JSON.stringify(response.user));
                        setSession({
                            user: response.user,
                            token: response.token,
                        });
                } catch (e:any) {
                    console.log(e);
                    if(e.res?.status === 401){
                        // notifyError('Forbidden Request', addNotification)
                        // setSession(null);
                        // navigate('/signin')
                    } else {
                        window.location.reload();
                    }
                }
            })();
        } else {
            navigate(`/party/${params.partyId}`)
        }
    }, [navigate, params.partyId, session]);

    return (
        <>
        </>
    )
}
