import {Box, Button, CircularProgress, Grid, Snackbar, SnackbarContent, Stack, Typography} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {MovieCard} from "../components/MovieCard/movieCard";
import React, {useEffect, useState} from "react";
import filmatchBE from "../service/FilmatchBE";
import {PartyPublicInfo} from "../models/PartyPublicInfo";
import {notifyError} from "../utils/notification";
import {useNotificationActions} from "../hooks/useNotification";
import {MovieResponse} from "../models/MovieResponse";
import {Category} from "../models/Category";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {useSession, useSessionAction} from "../hooks/useSession";
import {SkeletonMovieCard} from "../components/MovieCard/skeletonMovieCard";
import {PartyCard} from "../components/partCard/partyCard";
import {LoadingButton} from "@mui/lab";
import {Colors} from "../utils/theme";
import DoneIcon from "@mui/icons-material/Done";

export const PartyPage: React.FC = () => {
    const { partyId,sequence} = useParams();
    const { addNotification } = useNotificationActions();
    const navigator = useNavigate();
    const session = useSession();
    const { setSession } = useSessionAction();
    const [partyLoading, setPartyLoading] = useState<boolean>(true);
    const [party, setParty] = useState<PartyPublicInfo>();
    const [categories, setCategories] = useState<Category[]>([]);
    const [movie, setMovie] = useState<MovieResponse>();
    useEffect(() => {
        (async () => {
            try {
                if(partyId){
                    setPartyLoading(true);
                    const party = await filmatchBE.getParty(partyId);
                    setParty(party);
                    if(sequence || sequence ==='0'){
                        setMovie(party?.selectedMovies[parseInt(sequence,10)])
                    }
                    setPartyLoading(false);
                }

            } catch (e: any) {
                if(e.res.status === 401){
                    notifyError('Forbidden Request', addNotification)
                } else {
                    notifyError('Errore nel caricamento del party', addNotification)
                }
            }
        })();
        (async () => {
            try {
                if(partyId){
                    const categories = await filmatchBE.getCategories();
                    setCategories(categories);
                }
            } catch (e: any) {
                if(e.res.status === 401){
                    notifyError('Forbidden Request', addNotification)
                    setSession(null);
                    navigator('/signin')
                } else {
                    notifyError('Errore nel caricamento delle categorie', addNotification)
                }
            }
        })();
        if(party?.selectedMovies.length && sequence && parseInt(sequence)>=party?.selectedMovies.length){
            navigator('/party/'+partyId);
        }
    }, [sequence]);

    useEffect(() => {
        if(!session?.token){
            navigator('/signin/fallback=true')
        }
    }, []);

    return(
                <Grid
                    container
                    component="form"
                    spacing={2}
                    direction="column"
                    alignItems="center"
                    justifyContent="start"
                    mt={4}
                >
                    {sequence && (parseInt(sequence) <=20) ?
                    <Grid item mt={-5}>
                        <Stack direction="row" alignItems="center">
                            <Button variant='text' startIcon={<ArrowBackIosIcon/>} onClick={() => {navigator(`/party/${partyId}`)}}> Party </Button>
                            <Typography ml={'20%'} color={Colors.Gray.main}>{parseInt(sequence)+1}/{party?.selectedMovies.length}</Typography>
                        </Stack>
                        {movie && partyId && sequence && !partyLoading ?
                            <Box width="100%">
                                <MovieCard movie={movie} categories={categories} partyId={partyId} sequence={sequence} watchRegion={party?.otherInformation.watch_region ?? ''}/>
                            </Box>
                                : <SkeletonMovieCard />}
                    </Grid> :
                        <Box>
                            {party ?
                                    <Grid item ml={3}>
                                        <PartyCard party={party}/>
                                    </Grid>
                                :
                                <CircularProgress />
                            }
                        </Box>
                    }
                </Grid>
    )
}
