import {UserPublicInfo} from "../models/User";

export function getSessionUser(): UserPublicInfo | null {
    const userStr = window.localStorage.getItem('user');
    if (!userStr) {
        return null;
    }
    return JSON.parse(userStr);
}

export function getSessionToken(): string | null {
    const token = window.localStorage.getItem('token');
    if (!token) {
        return null;
    }
    return token;
}
