import apiCalls from "../utils/fetch";
import {LoginResponse} from "../models/LoginResponse";
import {PartyCreateRequest} from "../models/PartyCreateRequest";
import {PartyPublicInfo} from "../models/PartyPublicInfo";
import {MovieResultVote} from "../models/MovieResponse";
import {UserPublicInfo} from "../models/User";

export const createParty = (party: PartyCreateRequest): any => apiCalls.post<LoginResponse>({
    endpoint: 'party/create',
    headers: {
        'Content-Type': 'application/json',
    },
    data: JSON.stringify({ ...party }),
});

export const getParty = (codeParty: string): any => apiCalls.get<PartyPublicInfo>({
    endpoint: `party/detail?codeParty=${codeParty}`,
    headers: {
        'Content-Type': 'application/json',
    },
});

export const findParty = (partyNameOrPartyCode: string): any => apiCalls.get<PartyPublicInfo>({
    endpoint: `party/find?partyNameOrPartyCode=${partyNameOrPartyCode}`,
    headers: {
        'Content-Type': 'application/json',
    },
});

export const searchParty = (partyNameOrPartyCode: string): any => apiCalls.get<PartyPublicInfo[]>({
    endpoint: `party/search?partyNameOrPartyCode=${partyNameOrPartyCode}`,
    headers: {
        'Content-Type': 'application/json',
    },
});

export const likeMovie = (codeParty: string, idMovie: string, username: string): any => apiCalls.post<string>({
    endpoint: `party/likeMovie?codeParty=${codeParty}&idMovie=${idMovie}&username=${username}`,
    headers: {
        'Content-Type': 'application/json',
    },
    data: JSON.stringify({})
});

export const dislikeMovie = (codeParty: string, idMovie: string, username: string): any => apiCalls.post<string>({
    endpoint: `party/dislikeMovie?codeParty=${codeParty}&idMovie=${idMovie}&username=${username}`,
    headers: {
        'Content-Type': 'application/json',
    },
    data: JSON.stringify({})
});

export const mehMovie = (codeParty: string, idMovie: string, username: string): any => apiCalls.post<string>({
    endpoint: `party/mehMovie?codeParty=${codeParty}&idMovie=${idMovie}&username=${username}`,
    headers: {
        'Content-Type': 'application/json',
    },
    data: JSON.stringify({})
});

export const getResultParty = (codeParty: string): any => apiCalls.get<MovieResultVote[]>({
    endpoint: `party/partyresult?codeParty=${codeParty}`,
    headers: {
        'Content-Type': 'application/json',
    },
});

export const joinParty = (user: UserPublicInfo, codeParty: string): any => apiCalls.post<string>({
    endpoint: `party/addMember?codeParty=${codeParty}`,
    headers: {
        'Content-Type': 'application/json',
    },
    data: JSON.stringify(user),
});

export const recentParty = (username: string): any => apiCalls.get<PartyPublicInfo[]>({
    endpoint: `party/recent?username=${username}`,
    headers: {
        'Content-Type': 'application/json',
    },
});
