import {
    AppBar,
    Box,
    Chip,
    CircularProgress,
    Dialog,
    Grid,
    IconButton,
    ImageListItem,
    Slide,
    Snackbar,
    SnackbarContent,
    Stack,
    Toolbar,
    Typography
} from "@mui/material";
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {PartyPublicInfo} from "../../models/PartyPublicInfo";
import {Colors} from "../../utils/theme";
import * as _ from "lodash";
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import filmatchBE from "../../service/FilmatchBE";
import {useSession, useSessionAction} from "../../hooks/useSession";
import DoneIcon from "@mui/icons-material/Done";
import {TransitionProps} from "@mui/material/transitions";
import CloseIcon from "@mui/icons-material/Close";
import {MovieDetail} from "../movie/movieDetail";
import {MovieResultVote} from "../../models/MovieResponse";
import {WatchProvider} from "../../models/WatchProviders";
import {Category} from "../../models/Category";
import {notifyError} from "../../utils/notification";
import {useNotificationActions} from "../../hooks/useNotification";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import {UserPublicInfo} from "../../models/User";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const PartyCard: React.FC<{ party: PartyPublicInfo }> = ({party}) => {
    const navigate = useNavigate();
    const session = useSession();
    const {setSession} = useSessionAction();
    const {addNotification} = useNotificationActions();
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [loadingButton, setLoadingButton] = useState<boolean>(false);
    const [watchProvidersList, setWatchProvidersList] = React.useState<string[]>([])
    const [selectedMovie, setSelectedMovie] = React.useState<MovieResultVote | undefined>(undefined)
    const [openDialog, setOpen] = React.useState(false)
    const [categoryList, setCategoryList] = React.useState<string[]>([])
    const [watchProviderFullList, setWatchProviderFullList] = React.useState<WatchProvider[]>([]);
    const [categoryFullList, setCategoryFullList] = React.useState<Category[]>([])
    const [partyResultVote, setPartyResultVote] = useState<MovieResultVote[]>([])
    const [loadingMovies, setLoadingMovies] = useState<boolean>(true)

    const handleClickOpenDialog = (movie: MovieResultVote) => {
        setSelectedMovie(movie);
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setSelectedMovie(undefined);
        setOpen(false);
    };

    useEffect(() => {
        party?.otherInformation?.with_watch_providers?.split('|')?.forEach((watchProvider) => {
            setWatchProvidersList((watchProvidersList) => {
                return [...watchProvidersList, watchProvider]
            });
        });
        party?.otherInformation?.with_genres?.split('|')?.forEach((category) => {
            if (category.length > 0) {
                setCategoryList((categoryList) => {
                    return [...categoryList, category]
                });
            }
        });
        (async () => {
            try {
                const categories = await filmatchBE.getCategories();
                setCategoryFullList(categories);
            } catch (e: any) {
            }
        })();
        (async () => {
            try {
                const watchProviders = await filmatchBE.getWatchProviders(party.otherInformation.watch_region ?? 'US');
                setWatchProviderFullList(watchProviders);
            } catch (e: any) {
            }
        })();
        (async () => {
            try {
                if (party.codeParty) {
                    const partyResultVoteResponse = await filmatchBE.getResultParty(party.codeParty);
                    setPartyResultVote(partyResultVoteResponse);
                    setLoadingMovies(false);
                }

            } catch (e: any) {
                setLoadingMovies(false);
                if (e.res.status === 401) {
                    notifyError('Forbidden Request', addNotification)
                    setSession(null);
                    navigate('/signin')
                } else {
                    notifyError('Errore nel caricamento dei risultati', addNotification)
                }
            }
        })();
    }, []);

    const joinParty = async () => {
        if (session?.user && party.codeParty) {
            setLoadingButton(true);
            await filmatchBE.joinParty(session?.user, party.codeParty);
            setLoadingButton(false);
            navigate('/party/' + party.codeParty + '/0');
        } else {
            navigate('/signin');
        }
    }

    const copyCodeParty = () => {
        setOpenSnackbar(true);
        navigator.clipboard.writeText(`https://filmatch.app/join/party/${party.codeParty}`);
    }

    const closeSnackBar = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };

    return (
        <Grid
            container
            component="form"
            direction="column"
            alignItems="start"
            justifyContent="center"
        >
            <Grid item width={360}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant={'h4'} fontWeight={600}>{party.partyName}</Typography>
                    <div onClick={copyCodeParty}>
                        <IosShareOutlinedIcon fontSize={'medium'}/>
                    </div>
                </Stack>
            </Grid>
            <Grid item>
                <Stack direction="row" justifyContent="start" alignItems="center">
                    <PermIdentityOutlinedIcon sx={{color: Colors.Gray.light}}/>
                    <Typography ml={1} variant={'body1'}
                                color={Colors.Gray.light}>{party.organizer.username}</Typography>
                </Stack>
            </Grid>
            <Grid item>
                <Box sx={{background: Colors.Red.dark}} mt={0.5} mb={0.5} p={0.2} pl={2} pr={2} borderRadius={0.3}>
                    <Stack direction="row" justifyContent="start" alignItems="center">
                        <GroupsOutlinedIcon sx={{color: Colors.Gray.light}}/>
                        <Typography ml={1} variant={'body1'}
                                    color={Colors.Gray.light}>{(party.members as UserPublicInfo[]).length}</Typography>
                    </Stack>
                </Box>
            </Grid>
            <Grid item>
                {categoryList?.length ?
                    <><Typography variant='h6' fontWeight={500}>Category:</Typography>
                        <Box sx={{display: 'flex', overflowX: 'auto', maxWidth: 350, height: 40, flexDirection: 'row'}}>
                            {_.uniq(categoryList).map((category) => {
                                return (
                                    <Chip label={category} variant='outlined' sx={{mr: 0.25, ml: 0.25}}/>
                                );
                            })}
                        </Box>
                    </> : null
                }
                {watchProvidersList?.length ?
                    <><Typography variant='h6' fontWeight={500}>Streaming Platform:</Typography>
                        <Box sx={{display: 'flex', overflowX: 'auto', maxWidth: 350, height: 40, flexDirection: 'row'}}>
                            {_.uniq(watchProvidersList).map((watchProvider) => {
                                return (
                                    <Chip label={watchProvider} variant='outlined' sx={{ml: 0.25, mr: 0.25}}/>
                                );
                            })}
                        </Box>
                    </> : null
                }
            </Grid>
            <Grid item>
                <Stack direction="column" justifyContent="space-around" alignItems="center">
                    <div onClick={joinParty}>
                        <Box sx={{
                            background: Colors.Red.dark,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mt: 1
                        }} width={370} height={30} p={0.2} borderRadius={0.3}>
                            <div>
                                {loadingButton ? <CircularProgress size={15} sx={{color: Colors.White.main}}/> :
                                    <Typography fontSize={10} fontWeight={600} component="div">
                                        VOTE
                                    </Typography>
                                }
                            </div>
                        </Box>
                    </div>
                </Stack>
            </Grid>
            <Grid item mt={1}>
                <Stack direction="row" justifyContent="start" alignItems="center">
                    <Typography variant='h6' fontWeight={600}>Recommended Movies</Typography>
                    <Chip sx={{ml: 1}} color={'primary'} label={`${party.selectedMovies.length}`}/>
                </Stack>
                {!loadingMovies ?
                    <div style={{display: 'flex', overflowX: 'auto', flexDirection: 'row', width: 380, height: 230}}>
                        {partyResultVote.map((item, index) => (
                            <div onClick={() => handleClickOpenDialog(item)}>
                                <ImageListItem sx={{ml: 1, mr: 1}}
                                               key={`https://image.tmdb.org/t/p/w200${item.poster_path}`}>
                                    <Chip sx={{mb: -5, ml: -1, position: 'relative'}} color={'primary'}
                                          label={`${index + 1}`}/>
                                    <img style={{height: 200, width: 150, borderRadius: 18}}
                                         src={`https://image.tmdb.org/t/p/w200${item.poster_path}`} alt={item.title}/>
                                </ImageListItem>
                            </div>
                        ))}
                    </div> : <CircularProgress/>
                }
            </Grid>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={1000}
                onClose={closeSnackBar}
            >
                <SnackbarContent style={{backgroundColor: Colors.Black.main, color: Colors.White.main}}
                                 message={<span style={{display: 'flex', alignItems: 'center'}}>
              <DoneIcon style={{marginRight: '8px'}}/>
              <Typography>Party copied to clipboard</Typography>
            </span>
                                 }/>
            </Snackbar>
            <Dialog
                fullScreen
                open={openDialog}
                onClose={handleCloseDialog}
                TransitionComponent={Transition}
                PaperProps={{
                    style: {
                        backgroundColor: Colors.Black.main,
                    },
                }}
            >
                <Box sx={{backgroundColor: Colors.Black.main}} height={900}>
                    <AppBar sx={{
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'flex-end',
                        bgcolor: Colors.Black.dark
                    }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleCloseDialog}
                                aria-label="close"
                            >
                                <CloseIcon/>
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <Box mt={2}>
                        {selectedMovie ? <MovieDetail movie={selectedMovie} watchProvider={watchProviderFullList}
                                                      category={categoryFullList}
                                                      watchRegion={party.otherInformation.watch_region ?? 'US'}/> : null}
                    </Box>
                </Box>

            </Dialog>
        </Grid>
    )
}
