import * as React from 'react';
import logo from '../../assets/filmatchlogosmall.png';
import {Paper, styled} from "@mui/material";
import {Colors} from "../../utils/theme";

const Logo = styled('img')(({ theme }) => ({
    display: 'flex',
    height: '50px',
    border:0,
    borderColor:'transparent',
    padding: theme.spacing(2),
}));

export default function AppBarCustom() {

    return (
        <Paper sx={{ position: 'fixed', top: 0, left: 0, right: 0, bgcolor:Colors.Black.dark, borderRadius:0 }} elevation={3}>
            <Logo src={logo}/>
        </Paper>
    );
}
