import {createTheme} from "@mui/material";

export const Colors = {
    Red: {
        light: '#fc596d',
        main: '#db0000',
        dark: '#831010',
    },
    Purple: {
        light: '#bfa5d7',
        main: '#a18fc7',
        dark: '#8a7bb6',
    },
    Blue: {
        light: '#547b8c',
        main: '#4055c6',
        dark: '#2F4858',
    },
    Green: {
        light: '#C9DAC3',
        main: '#96B6A8',
        dark: '#6B918F',
    },
    Yellow: {
        light: '#fafaa4',
        main: '#f0f09d',
        dark: '#ffc84b',
        warning: '#f4a259',
    },
    Orange: {
        light: '#ffb881',
        main: '#ff9e4a',
        dark: '#ff8b1a',
    },
    Gray: {
        light: '#e7e7e7',
        main: '#8f8f8f',
        dark: '#383838',
    },
    White: {
        light: '#ffffff',
        main: '#e3e3e3',
        dark: '#d0d0d0',
    },
    Black: {
        light: '#2a2a2a',
        main: '#121212',
        dark: '#000000',
    },
    Main: {
        light: '#ffffff',
        main: '#db0000',
        dark: '#564d4d',
    }

};

export const darkTheme = createTheme({
    shape: {
        borderRadius: 18,
    },
    components:{
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius:10,
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    borderRadius:20,
                }
            }
        },
    },
    palette: {
        mode: 'dark',
        primary: {
            light: Colors.Main.light,
            main: Colors.Red.dark,
            dark: Colors.Main.dark,
        },
        secondary: {
            light: Colors.Green.light,
            main: Colors.Green.main,
            dark: Colors.Green.dark,
        },
        success: {
            light: Colors.Green.light,
            main: Colors.Green.main,
            dark: Colors.Green.dark,
        },
        error: {
            light: Colors.Red.light,
            main: Colors.Red.main,
            dark: Colors.Red.dark,
        },
    },
});
