import { MovieResultVote} from "../../models/MovieResponse";
import {Box, Chip, Stack, Typography} from "@mui/material";
import React from "react";
import {Colors} from "../../utils/theme";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import * as _ from "lodash";
import {Category} from "../../models/Category";
import {WatchProvider} from "../../models/WatchProviders";
import PollOutlinedIcon from '@mui/icons-material/PollOutlined';

export const MovieDetail: React.FC<{movie:MovieResultVote,watchProvider:WatchProvider[],category:Category[],watchRegion:string}> = ({movie,watchProvider,category,watchRegion}) => {
    return (
        <Box bgcolor={Colors.Black.main}>
            <img style={{height: 280, width: 190, borderRadius: 18, marginLeft:'27%'}}
                 src={`https://image.tmdb.org/t/p/w200${movie.poster_path}`} alt={movie.title}/>
            <Stack direction="column" justifyContent="center" alignItems="start" ml={2}>
                <Typography mt={2} gutterBottom variant="h5" component="div">
                    {movie.title}
                </Typography>
                <Stack direction="row" mt={-1} justifyContent="start" alignItems="center">
                    <Typography gutterBottom variant="subtitle2" color={'text.secondary'} component="div">
                        {movie.release_date.slice(0, 4)}
                    </Typography>
                    <Stack ml={1} direction="row" justifyContent="start" color={'text.secondary'} alignItems="left">
                        <StarHalfIcon/>
                        <Typography gutterBottom variant="body1" component="div">
                            {movie.vote_average.toPrecision(2)}
                        </Typography>
                        <Typography gutterBottom variant="body1" component="div">
                            /10
                        </Typography>
                        <Typography gutterBottom variant="body2" color="text.secondary" component="div">
                            ({movie.vote_count})
                        </Typography>
                    </Stack>
                    <Stack ml={1} direction="row" justifyContent="start" color={'text.secondary'} alignItems="left">
                        <PollOutlinedIcon/>
                        <Typography gutterBottom variant="body1" component="div">
                            {movie.resultVote}
                        </Typography>
                    </Stack>
                </Stack>
                <Typography fontWeight={500} fontSize={'h6'}>Category</Typography>
                {movie.genre_ids?.length ?
                    <Box sx={{
                        display: 'flex',
                        overflowX: 'auto',
                        width: 370,
                        height: 40,
                        flexDirection: 'row',
                    }}>
                        {_.uniq(movie.genre_ids)?.map((genreId) => {
                            const cat = category.find((category) => parseInt(category.id) === genreId);
                            if (cat) {
                                return (
                                    <Chip size="small" variant="outlined" sx={{m: 0.3}} label={cat.name}></Chip>
                                )
                            }
                        })}
                    </Box> : null
                }
                <Typography fontWeight={500} fontSize={'h6'}>Streaming Platform:</Typography>
                {movie.watchProviders?.results[watchRegion].flatrate?.length ?
                    <>
                        <Box mt={1}
                             sx={{display: 'flex', overflowX: 'auto', width: 370, height: 40, flexDirection: 'row'}}>
                            {_.uniq(movie.watchProviders?.results[watchRegion].flatrate)?.map((provider) => {
                                return (
                                    <Chip label={provider.provider_name} variant='outlined'/>
                                );
                            })}
                        </Box>
                    </> : null
                }
                <Typography mt={1} fontWeight={500} fontSize={'h6'}>Summary:</Typography>
                <Box width='80%'>
                    {movie.overview ? <Typography variant="body2" alignItems={'center'} color="text.secondary">
                        {movie.overview.slice(0,400)+'...'}
                    </Typography> : null}
                </Box>
            </Stack>
        </Box>
    )
}
