import {Box, Card, CardContent, Skeleton, Stack, Typography} from "@mui/material";
import React from "react";
import {PartyPublicInfo} from "../../models/PartyPublicInfo";
import {useNavigate} from "react-router-dom";

export const RowPartySkeleton: React.FC = () => {

    return (
        <Card sx={{ display: 'flex', width:350 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', width:'100%'}}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={200} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
                    </Stack>
                    <Stack direction="row" justifyContent="start" alignItems="center">
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={90} />
                    </Stack>
                    <Stack direction="row" justifyContent="start" alignItems="center">
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={60} />
                    </Stack>
                </CardContent>
            </Box>
        </Card>
    )
}
