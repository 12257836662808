import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    CardMedia,
    Chip,
    Grid,
    Stack,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useSession, useSessionAction} from "../hooks/useSession";
import {useNavigate} from "react-router-dom";
import filmatchBE from "../service/FilmatchBE";
import {notifyError} from "../utils/notification";
import {UserPublicInfo} from "../models/User";
import {useNotificationActions} from "../hooks/useNotification";
import {WatchProvider} from "../models/WatchProviders";
import {Category} from "../models/Category";
import {ArrowDownwardOutlined} from "@mui/icons-material";
import FilmatchBE from "../service/FilmatchBE";

export const ProfilePage: React.FC = () => {
    const session = useSession();
    const navigate = useNavigate();
    const { setSession } = useSessionAction();
    const { addNotification } = useNotificationActions();
    const [profile,setProfile]= React.useState<UserPublicInfo>();
    const [watchProviders, setWatchProviders] = useState<WatchProvider[]>([]);
    const [categoryList, setCategoryList] = useState<Category[]>([]);

    const selectCategory = (category: Category) => {
        category.selected = !category.selected;
        setCategoryList(categoryList.map((c) => {
            if (c.id === category.id) {
                c.selected = category.selected;
            }
            return c;
        }));
        FilmatchBE.updateCategories(categoryList.filter((c) => c.selected))
    }

    const selectWatchProvider = (watchProvider: WatchProvider) => {
        watchProvider.selected = !watchProvider.selected;
        setWatchProviders(watchProviders.map((c) => {
            if (c.provider_id === watchProvider.provider_id) {
                c.selected = watchProvider.selected;
            }
            return c;
        }));
        FilmatchBE.updateWatchProvider(watchProviders.filter((c) => c.selected))
    }

    useEffect(() => {
        filmatchBE.getCategories().then(setCategoryList)
            .catch((e:any) => {
                if(e.res.status === 401){
                    notifyError('Forbidden Request', addNotification)
                    navigate('/signin')
                } else {
                    notifyError('Errore nel reperimento categorie', addNotification)
                }
            });
        filmatchBE.getWatchProviders(session?.user.country ?? 'US').then((watchProviders) => {
            setWatchProviders(watchProviders.sort((a,b) => a.display_priority - b.display_priority))
        })
            .catch((e:any) => {
                if(e.res.status === 401){
                    notifyError('Forbidden Request', addNotification)
                    setSession(null);
                    navigate('/signin')
                } else {
                    notifyError('Errore nel reperimento delle piattaform Streaming', addNotification)
                }
            });
    }, []);

    useEffect(() => {
        const cat = categoryList.map((cl) => {
            if(profile?.categories?.find((c) => c.id===cl.id)){
                cl.selected=true;
            }
            return cl;
        })
        setCategoryList(cat);
        const wp = watchProviders.map((wl) => {
            if(profile?.watchProviders?.find((w) => w.provider_id===wl.provider_id)){
                wl.selected=true;
            }
            return wl;
        })
        setWatchProviders(wp);
    }, [profile]);

    useEffect(() => {
        (async () => {
            try {
                const profileResult = await filmatchBE.getProfile();
                setProfile(profileResult);

            } catch (e:any) {
                if(e.res.status === 401){
                    notifyError('Forbidden Request', addNotification)
                    setSession(null);
                    navigate('/signin')
                } else {
                    notifyError('Errore on loading profile', addNotification)
                }
            }
        })();
    }, []);

    const logout = () => {
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('user');
        setSession(null);
        navigate('/login');
    }
    return (
        <Grid
            container
            component="div"
            direction="column"
            justifyContent="center"
            mt={6}
            mb={6}
        >
            <Grid item marginLeft={'42%'}>
                <Typography variant="h6">
                    Profile
                </Typography>
            </Grid>
            <Grid item ml={2} mt={2}>
                <Stack direction="row" justifyContent="start" alignItems="left">
                    <Typography gutterBottom variant="body1" component="div">
                        Name:
                    </Typography>
                    <Typography ml={1} gutterBottom variant="body1" color="text.secondary" component="div">
                        {profile?.name}
                    </Typography>
                </Stack>
            </Grid>
            <Grid item ml={2}>
                <Stack direction="row" justifyContent="start" alignItems="left">
                    <Typography gutterBottom variant="body1" component="div">
                        Username:
                    </Typography>
                    <Typography ml={1} gutterBottom variant="body1" color="text.secondary" component="div">
                        {profile?.username}
                    </Typography>
                </Stack>
            </Grid>
            {profile?.country ?
                <Grid item ml={2}>
                    <Stack direction="row" justifyContent="start" alignItems="center">
                        <Typography gutterBottom variant="body1" component="div">
                            Country:
                        </Typography>
                        <Typography ml={1} gutterBottom variant="body1" color="text.secondary" component="div">
                            {profile?.country}
                        </Typography>
                        <Box ml={1}>
                            <img
                                loading="lazy"
                                width="35"
                                height={'20'}
                                srcSet={`https://flagcdn.com/w40/${profile?.country.toLowerCase()}.png 2x`}
                                src={`https://flagcdn.com/w20/${profile?.country.toLowerCase()}.png`}
                                alt=""
                            />
                        </Box>
                    </Stack>
                </Grid>
                :
                null
            }
            <Grid item ml={2}>
                <Accordion sx={{maxWidth:300}}>
                    <AccordionSummary
                        expandIcon={<ArrowDownwardOutlined/>}
                    >
                        <Typography>Favorite Categories</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{marginTop:2,overflowY:'auto', maxHeight:200}}>
                        {categoryList.map((category) => {
                            return (
                                <>
                                    {category.selected ? <Chip sx={{m:0.3, backgroundColor:'#831010'}} label={category.name} variant='filled' onClick={() => selectCategory(category)}/>
                                        :
                                        <Chip sx={{m:0.3}} label={category.name} variant='outlined' onClick={() => selectCategory(category)}/>
                                    }
                                </>
                            )
                        })}
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item mt={1} ml={2}>
                <Accordion sx={{maxWidth:300}}>
                    <AccordionSummary
                        expandIcon={<ArrowDownwardOutlined/>}
                    >
                        <Typography>Favorite Platform</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{marginTop:2,overflowY:'auto', maxHeight:200}}>
                        {watchProviders.map((watchProvider) => {
                            return (
                                <>
                                    {watchProvider.selected ? <Chip sx={{m:0.3, backgroundColor:'#831010'}} label={watchProvider.provider_name} variant='filled' onClick={() => selectWatchProvider(watchProvider)}/>
                                        :
                                        <Chip sx={{m:0.3}} label={watchProvider.provider_name} variant='outlined' onClick={() => selectWatchProvider(watchProvider)}/>
                                    }
                                </>
                            )
                        })}
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item ml={2} mt={1}>
                <Button variant={'contained'} onClick={logout}>LogOut</Button>
            </Grid>
        </Grid>
    )
}
