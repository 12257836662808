import React, {useEffect} from "react";
import { Box, Grid,Tab, Tabs} from "@mui/material";
import {CustomTabPannel} from "../components/tabPannels/CustomTabPannel";
import {SignIn} from "../components/signin/signin";
import {SignUp} from "../components/signup/signup";
import {useNavigate, useParams} from "react-router-dom";
import {useSession, useSessionAction} from "../hooks/useSession";
import filmatchBE from "../service/FilmatchBE";
import {notifyError} from "../utils/notification";
import {useNotificationActions} from "../hooks/useNotification";
import generateRandomString from "../utils/generateRandomString";

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const AccessPage: React.FC<{redirect?:boolean}> = ({redirect}) => {
    const [value, setValue] = React.useState(0);
    const session = useSession();
    const setSession = useSessionAction();
    const { addNotification } = useNotificationActions();
    const navigate = useNavigate();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        (async () => {
            try {
                if(!session?.token){
                    const response= await filmatchBE.signup(generateRandomString(6), generateRandomString(6),generateRandomString(6));
                    window.localStorage.setItem('token', response.token);
                    window.localStorage.setItem('user', JSON.stringify(response.user));
                    setSession({
                        user: response.user,
                        token: response.token,
                    });
                }
                navigate('/home');
            } catch (e:any) {
                if(e.res?.status === 401){
                    notifyError('Forbidden Request', addNotification)
                    setSession(null);
                    navigate('/signin')
                } else {
                    window.location.reload();
                }
            }
        })();
    },[]);

    return (
        <></>
    )
}

AccessPage.displayName = 'LoginPage';
