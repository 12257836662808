import {Box, CssBaseline, Grid, styled, ThemeProvider, Typography} from "@mui/material";
import {darkTheme} from "../utils/theme";
import React from "react";
import logoBig from "../assets/filmatchlogowhite.png";

export const LogoBig = styled('img')(({ theme }) => ({
    display: 'flex',
    height: '200px',
    border:0,
    borderColor:'transparent',
    padding: theme.spacing(2),
}));

export const DesktopSoonPage: React.FC = () => {
    return (
        <Box>
            <ThemeProvider theme={darkTheme}>
                <CssBaseline/>
                <Grid
                    container
                    component="form"
                    spacing={2}
                    display={'flex'}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    mt={4}
                >
                    <Grid item>
                        <LogoBig src={logoBig}/>
                    </Grid>
                    <Grid item>
                        <Typography variant={'h6'}>Desktop version will be available soon</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant={'h4'}>Please use a mobile device</Typography>
                    </Grid>
                </Grid>
            </ThemeProvider>
        </Box>

    )
}
