import {BottomNavigation, BottomNavigationAction, Box, Paper} from "@mui/material";
import React from "react";
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import PersonIcon from "@mui/icons-material/Person";
import {useNavigate} from "react-router-dom";

export const Navbar: React.FC = () => {
    const navigate = useNavigate();
    const [value, setValue] = React.useState(0);
    const createParty = () => {
        navigate('/createParty');
    }
    const goHome = () => {
        navigate('/home');
    }

    const goProfile = () => {
        navigate('/profile');
    }

    const searchParty = () => {
        navigate('/searchParty');
    }
    return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
            >
                <BottomNavigationAction onClick={goHome} icon={<HomeIcon/>} />
                <BottomNavigationAction onClick={searchParty} icon={<SearchIcon/>} />
                <BottomNavigationAction onClick={createParty} icon={<AddIcon/>} />
                <BottomNavigationAction onClick={goProfile} icon={<PersonIcon/>} />
            </BottomNavigation>
        </Paper>
    )
}
