import {
    Box,
    Card, CardActionArea,
    CardContent,
    Chip, CircularProgress, Snackbar, SnackbarContent,
    Stack,
    Typography
} from "@mui/material";
import React, {useEffect} from "react";
import {PartyPublicInfo} from "../../models/PartyPublicInfo";
import {useNavigate} from "react-router-dom";
import * as _ from 'lodash';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import {Colors} from "../../utils/theme";
import ShareIcon from "@mui/icons-material/Share";
import DoneIcon from '@mui/icons-material/Done';

export const RowParty: React.FC<{party: PartyPublicInfo,index:number}> = ({party,index}) => {
    const navigate = useNavigate();
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [loadingButton, setLoadingButton] = React.useState(false);
    const [categoryList, setCategoryList] = React.useState<string[]>([])

    useEffect(() => {
        party?.otherInformation?.with_genres?.split('|')?.forEach((category) => {
            if(category.length > 0){
                setCategoryList((categoryList) => {
                    return [...categoryList, category]
                });
            }
        });
    }, []);

    const detailParty = () => {
        navigate(`/party/${party.codeParty}`)
    }

    const copyCodeParty = () => {
        setOpenSnackbar(true);
        navigator.clipboard.writeText(`https://filmatch.app/join/party/${party.codeParty}`);
    }

    const closeSnackBar = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };

    return (
        <Card sx={{ display: 'flex', width:350 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', width:'100%'}}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography component="div" variant="h6">
                            {party.partyName}
                        </Typography>
                        <Box sx={{display:'flex', justifyContent:'center'}}>
                            <Box sx={{background:Colors.Yellow.dark}} width={50} p={0.2} borderRadius={0.3} mr={1}>
                                <Stack direction="row" justifyContent="center" alignItems="center">
                                    <Typography fontSize={10} color={Colors.Black.main} fontWeight={600} component="div">
                                        {party.members as number}
                                    </Typography>
                                    <GroupsOutlinedIcon fontSize={'small'} sx={{color:Colors.Black.main, ml:0.5}}/>
                                </Stack>
                            </Box>
                            <Box  sx={{background:Colors.Red.dark, display:'flex', justifyContent:'center', alignItems:'center'}} width={50} height={23} p={0.2} borderRadius={0.3}>
                                <div onClick={detailParty}>
                                    {loadingButton ? <CircularProgress size={15} sx={{color:Colors.White.main}} /> : <Typography fontSize={10} fontWeight={600} component="div">
                                        DETAIL
                                    </Typography>
                                    }
                                </div>
                            </Box>
                        </Box>
                    </Stack>
                    <CardActionArea sx={{width:85}}>
                        <Box sx={{background:Colors.White.main}} width={85} p={0.2} borderRadius={0.3}>
                            <div onClick={copyCodeParty}>
                                <Stack direction="row" justifyContent="center" alignItems="center">
                                    <Typography fontSize={10} fontWeight={600} color={Colors.Gray.dark} component="div">
                                        Share
                                    </Typography>
                                    <ShareIcon fontSize={'small'} sx={{color:Colors.Gray.dark}}/>
                                </Stack>
                            </div>
                        </Box>
                    </CardActionArea>
                    <Stack direction="row" justifyContent="start" alignItems="center" sx={{mt:1}}>
                        <Typography component="div" variant="subtitle2" fontWeight={600}>
                            Organizer:
                        </Typography>
                        <Typography variant="subtitle2" component="div">
                            {party.organizer.username}
                        </Typography>
                    </Stack>
                    { categoryList?.length ?
                        <><Typography variant='subtitle2' fontWeight={600} sx={{mt:1}}>Category:</Typography>
                            <Box sx={{display: 'flex', overflowX: 'auto', width: 325, height: 40, flexDirection: 'row'}}>
                            {_.uniq(categoryList).map((category) => {
                                return (
                                        <Chip label={category} variant='outlined'/>
                                );
                            })}
                            </Box>
                        </> : null
                    }
                    {/*watchProvidersList?.length ?
                        <>
                            <Typography variant='subtitle2' fontWeight={600} mt={1}>Streaming
                                Platform:
                            </Typography>
                            <Box sx={{display: 'flex', overflowX: 'auto', width: 325, height: 40, flexDirection: 'row'}}>
                                {_.uniq(watchProvidersList).map((watchProvider, index) => {
                                    return (
                                        <Chip sx={{mr: 1}} label={watchProvider} variant='outlined'/>
                                    );
                                })}
                            </Box>
                        </> : null
                    */}
                </CardContent>
            </Box>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={1000}
                onClose={closeSnackBar}
            >
                <SnackbarContent style={{backgroundColor:Colors.Black.main, color:Colors.White.main}} message={<span style={{ display: 'flex', alignItems: 'center' }}>
              <DoneIcon style={{ marginRight: '8px' }} />
              <Typography>Party copied to clipboard</Typography>
            </span>
                }/>
            </Snackbar>
        </Card>
    )
}
