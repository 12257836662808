import {MovieResponse} from "../../models/MovieResponse";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Chip,
    CircularProgress, Grid,
    IconButton,
    Stack,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Category} from "../../models/Category";
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import filmatchBE from "../../service/FilmatchBE";
import {useSession} from "../../hooks/useSession";
import {useNavigate} from "react-router-dom";
import * as _ from "lodash";
import {Colors} from "../../utils/theme";

export const MovieCard: React.FC<{movie: MovieResponse,categories: Category[], partyId:string, sequence:string,watchRegion:string}> = (movie) => {
    const session = useSession();
    const navigator = useNavigate();
    const  [loadingLikeButton, setLoadingLikeButton] = React.useState<boolean>(false);
    const [loadingDislikeButton, setLoadingDislikeButton] = React.useState<boolean>(false);
    const [loadingMehButton, setLoadingMehButton] = React.useState<boolean>(false);


    const navigate = () => {
        navigator(`/party/${movie.partyId}/${parseInt(movie.sequence,10)+1}`);
    }
    const likeVote = async () => {
        setLoadingLikeButton(true)
        await filmatchBE.likeMovie(movie.partyId, movie.movie.id.toString() , session?.user.username ?? '');
        setLoadingLikeButton(false)
        navigate();
    }


    const dislikeVote = async () => {
        setLoadingDislikeButton(true)
        await filmatchBE.dislikeMovie(movie.partyId, movie.movie.id.toString() , session?.user.username ?? '');
        setLoadingDislikeButton(false)
        navigate();
    }

    const mehVote = async () => {
        setLoadingMehButton(true)
        await filmatchBE.mehMovie(movie.partyId, movie.movie.id.toString() , session?.user.username ?? '');
        setLoadingMehButton(false)
        navigate();
    }

    return (
            <Box position="relative" width="100%">
                <div style={{width: '100%',
                    height: '550px',
                    position: 'relative',// Altezza desiderata del contenitore
                    overflow: 'hidden',}}>
                    <img
                        loading="lazy"
                        style={{
                            objectFit: 'cover',
                            width: 380, // L'immagine si estenderà orizzontalmente per adattarsi alla larghezza del contenitore
                            height: '100%', // L'altezza dell'immagine sarà uguale all'altezza del contenitore
                            objectPosition: 'center bottom',}}
                        srcSet={`https://image.tmdb.org/t/p/w500${movie.movie.poster_path}`}
                        src={`https://image.tmdb.org/t/p/w500${movie.movie.poster_path}`}
                        alt=""
                    />
                </div>
                <div style={{
                    position: 'absolute',
                    bottom: -1,
                    left: -1,
                    width: '101%',
                    height: '82%',
                    backgroundImage: 'linear-gradient(0deg, rgba(18,18,18,1) 49%, rgba(18,18,18,1) 58%, rgba(255,255,255,0) 100%)'
                }}>
                </div>
                <Box position={'absolute'} top={280} width={'100%'}>
                    <Stack direction="row" justifyContent="space-around" alignItems="center" marginBottom={3}>
                        {!loadingDislikeButton ? <IconButton aria-label="dilike" onClick={dislikeVote}><ThumbDownIcon/></IconButton>
                            : <CircularProgress size={30} />}
                        {!loadingMehButton ? <IconButton aria-label="meh" onClick={mehVote}><ThumbsUpDownIcon/></IconButton>
                            : <CircularProgress size={30} />}
                        {!loadingLikeButton ? <IconButton aria-label="like" onClick={likeVote}><ThumbUpIcon/></IconButton>
                            : <CircularProgress size={30} />}
                    </Stack>
                </Box>
                <Box position={'absolute'} top={315} ml={1} width={'100%'}>
                    <Typography gutterBottom variant="h5" component="div">
                        {movie.movie.title}
                    </Typography>
                    <Stack direction="row" mt={-1} justifyContent="start" alignItems="center">
                        <Typography gutterBottom variant="subtitle2" color={'text.secondary'} component="div">
                            {movie.movie.release_date.slice(0,4)}
                        </Typography>
                        <Stack ml={1} direction="row" justifyContent="start" color={'text.secondary'} alignItems="left">
                            <StarHalfIcon/>
                            <Typography gutterBottom variant="body1" component="div">
                                {movie.movie.vote_average.toPrecision(2)}
                            </Typography>
                            <Typography gutterBottom variant="body1" component="div">
                                /10
                            </Typography>
                            <Typography gutterBottom variant="body2" color="text.secondary" component="div">
                                ({movie.movie.vote_count})
                            </Typography>
                        </Stack>
                    </Stack>
                    { movie.movie.genre_ids?.length ?
                            <Box sx={{display: 'flex', overflowX: 'auto', width: 325, height: 40, flexDirection: 'row'}}>
                                {_.uniq(movie.movie.genre_ids)?.map((genreId) => {
                                    const category = movie.categories.find((category) => parseInt(category.id) === genreId);
                                    if(category){
                                        return (
                                            <Chip size="small" variant="outlined" sx={{m:0.3}} label={category.name}></Chip>
                                        )
                                    }
                                })}
                            </Box> : null
                    }
                    {movie.movie.overview ? <Typography variant="body2" color="text.secondary">
                        {movie.movie.overview.slice(0,300)}...
                    </Typography> : null}
                    { movie.movie.watchProviders?.results[movie.watchRegion].flatrate?.length ?
                        <>
                            <Box mt={1} sx={{display: 'flex', overflowX: 'auto', width: 325, height: 40, flexDirection: 'row'}}>
                                {_.uniq(movie.movie.watchProviders?.results[movie.watchRegion].flatrate)?.map((provider) => {
                                    return (
                                        <Chip label={provider.provider_name} variant='outlined'/>
                                    );
                                })}
                            </Box>
                        </> : null
                    }
                </Box>
            </Box>
    )
}
