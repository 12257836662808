import {Box, CircularProgress, Grid, InputBase, Paper, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useNotificationActions} from "../hooks/useNotification";
import {PartyPublicInfo} from "../models/PartyPublicInfo";
import filmatchBE from "../service/FilmatchBE";
import {notifyError} from "../utils/notification";
import {RowParty} from "../components/partCard/rowParty";
import {WatchProvider} from "../models/WatchProviders";
import {Category} from "../models/Category";

export const SearchPartyPage: React.FC = () => {
    const { addNotification } = useNotificationActions();
    const [query, setQuery] = React.useState('');
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [parties, setParties] = React.useState<PartyPublicInfo[]>();

    const onSubmit = async () => {
        setParties(await filmatchBE.searchParty(query));
    }

    useEffect(() => {
        (async () => {
            try {
                if(query?.trim()?.length > 0){
                    setLoadingSearch(true);
                    setParties(await filmatchBE.searchParty(query));
                    setLoadingSearch(false);
                } else {
                    setParties(undefined);
                }
            } catch (e) {
                notifyError('Errore nel caricamento del party', addNotification)
            }
        })();
    }, [query]);

    return (
        <Grid
            container
            spacing={2}
            direction="column"
            alignItems="center"
            justifyContent="start"
            mt={4}
        >
            <Grid container direction="column" alignItems="center"
                  justifyContent="start"
                  mt={4}>
                <Grid item>
                    <Typography component="h1" variant="h5">
                        Find Party
                    </Typography>
                </Grid>
                <Grid item marginTop={1}>
                    <Paper sx={{
                        p: '2px 4px', display: 'flex', alignItems: 'center',
                        width: 215
                    }}>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            value={query}
                            onChange={(e) => {
                                setQuery(e.target.value);
                            }}
                            onFocus={(e) => {
                                setQuery(e.target.value);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    onSubmit();
                                }
                            }}
                            placeholder="Search from code or name"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </Paper>
                </Grid>
                <Grid item mt={2} ml={1.5}>
                    {loadingSearch ? <CircularProgress /> :
                        <Box>
                            {parties?.length ? parties?.map((party,index) => {
                                return (
                                    <Box p={1}>
                                        <RowParty party={party} index={index}/>
                                    </Box>

                                )
                            }) : <Typography>No party found</Typography>}
                        </Box>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}
