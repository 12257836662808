import React, { useCallback, useMemo, useState } from 'react';

export type Notification = {
    message: string | string[];
    type: 'error' | 'success' | 'info';
};

export const NotificationActionsContext = React.createContext<{
    addNotification:(notification: Notification) => void;
    removeNotification: () => void;
}>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    addNotification: () => {
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    removeNotification: () => {
    },
});

export const NotificationValueContext = React.createContext<Notification | null>(null);

export const NotificationProvider = ({ children }: { children: React.ReactNode }) => {
    const [notification, setNotification] = useState<Notification | null>(null);

    const addNotification = useCallback(
        (n: Notification) => setNotification(n),
        [],
    );
    const removeNotification = useCallback(() => setNotification(null), []);

    const actions = useMemo(
        () => ({
            addNotification,
            removeNotification,
        }),
        [addNotification, removeNotification],
    );

    return (
        <NotificationValueContext.Provider value={notification}>
            <NotificationActionsContext.Provider value={actions}>
                {children}
            </NotificationActionsContext.Provider>
        </NotificationValueContext.Provider>
    );
};
