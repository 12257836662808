import apiCalls from "../utils/fetch";
import {Category} from "../models/Category";
import {AvailableRegion} from "../models/AvailableRegion";


export const availableRegionApi = (): any => apiCalls.get<AvailableRegion[]>({
    endpoint: 'watchProvider/availableRegions',
    headers: {
        'Content-Type': 'application/json',
    },
});
