import React, { useEffect, useState } from 'react';
import {
    IconButton, Snackbar, SnackbarContent, Theme, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Notification as NotificationModel } from './notificationContext';
import {useNotificationActions, useNotificationValue} from "../../hooks/useNotification";
import {Colors} from "../../utils/theme";

type Color = (theme: Theme) => string;

const notificationColors: Record<NotificationModel['type'], Color> = {
    error: (theme) => theme.palette.error.main,
    success: (theme) => theme.palette.success.main,
    info: (theme) => Colors.Blue.main,
};

const Notification: React.FC = () => {
    const notification = useNotificationValue();
    const { removeNotification } = useNotificationActions();
    const [snackbar, setSnackbar] = useState<{
        open: boolean;
        message: NotificationModel['message'];
        type: NotificationModel['type'];
    }>({ open: false, message: '', type: 'success' });

    useEffect(() => {
        if (notification) {
            setSnackbar({
                open: true,
                message: notification.message,
                type: notification.type,
            });
        }
    }, [notification]);

    const handleClose = () => {
        setSnackbar({ message: '', type: snackbar.type, open: false });
        removeNotification();
    };

    return (
        <Snackbar
            open={snackbar.open}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            autoHideDuration={6000}
            onClose={handleClose}
        >
            <SnackbarContent
                message={
                    Array.isArray(snackbar.message) ? (
                        snackbar.message.map((msg, i) => <NotificationMessage key={i} message={msg}/>)
                    ) : (
                        <NotificationMessage message={snackbar.message}/>
                    )
                }
                sx={{ backgroundColor: notificationColors[snackbar.type] }}
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                        <CloseIcon fontSize="small"/>
                    </IconButton>
                }
            />
        </Snackbar>
    );
};

const NotificationMessage: React.FC<{ message: string }> = ({ message }) => <Typography variant="body2">{message}</Typography>;

export default Notification;
