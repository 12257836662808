import {LoginResponse} from "../models/LoginResponse";
import {login, signup} from "../api/security.api";
import {Category} from "../models/Category";
import {categories} from "../api/category.api";
import {WatchProvider} from "../models/WatchProviders";
import {watchProvidersApi} from "../api/watchProviders.api";
import {PartyCreateRequest} from "../models/PartyCreateRequest";
import {PartyPublicInfo} from "../models/PartyPublicInfo";
import {
    createParty,
    dislikeMovie,
    findParty,
    getParty,
    getResultParty,
    joinParty,
    likeMovie,
    mehMovie, recentParty, searchParty
} from "../api/party.api";
import {MovieResultVote} from "../models/MovieResponse";
import {UserPublicInfo} from "../models/User";
import {AvailableRegion} from "../models/AvailableRegion";
import {availableRegionApi} from "../api/availableRegion.api";
import {getProfile, updateCategories, updateWatchProvider} from "../api/profile.api";

class FilmatchBE {
    login = async (username: string, password: string): Promise<LoginResponse> => (await login(username, password)).data as LoginResponse;

    signup = async (username: string, password: string, email?: string,name?:string,country?:string): Promise<LoginResponse> => (await signup(username, password, email,name,country)).data as LoginResponse;

    getCategories = async (): Promise<Category[]> =>  (await categories()).data as Category[];

    updateCategories = async (categories: Category[]) => (await updateCategories(categories)).data as void

    updateWatchProvider = async (watchProviders: WatchProvider[]) => (await updateWatchProvider(watchProviders)).data as void

    getAvailableRegions = async (): Promise<AvailableRegion[]> => (await availableRegionApi()).data as AvailableRegion[];

    getWatchProviders = async (region:string): Promise<WatchProvider[]> => (await watchProvidersApi(region)).data as WatchProvider[];

    createParty = async (party: PartyCreateRequest): Promise<PartyPublicInfo> => (await createParty(party)).data as PartyPublicInfo;

    getParty = async (codeParty: string): Promise<PartyPublicInfo> => (await getParty(codeParty)).data as PartyPublicInfo;

    findParty = async (partyNameOrPartyCode: string): Promise<PartyPublicInfo> => (await findParty(partyNameOrPartyCode)).data as PartyPublicInfo;

    searchParty = async (partyNameOrPartyCode: string): Promise<PartyPublicInfo[]> => (await searchParty(partyNameOrPartyCode)).data as PartyPublicInfo[];

    likeMovie = async (codeParty: string, idMovie: string, username: string): Promise<string> => (await likeMovie(codeParty, idMovie, username)).data as string;

    dislikeMovie = async (codeParty: string, idMovie: string, username: string): Promise<string> => (await dislikeMovie(codeParty, idMovie, username)).data as string;

    mehMovie = async (codeParty: string, idMovie: string, username: string): Promise<string> => (await mehMovie(codeParty, idMovie, username)).data as string;

    getResultParty = async (codeParty: string): Promise<MovieResultVote[]> => (await getResultParty(codeParty)).data as MovieResultVote[];

    joinParty = async (user: UserPublicInfo, codeParty: string): Promise<string> => (await joinParty(user, codeParty)).data as string;

    recentParty = async (username: string): Promise<PartyPublicInfo[]> => (await recentParty(username)).data as PartyPublicInfo[];

    getProfile = async (): Promise<UserPublicInfo> => (await getProfile()).data as UserPublicInfo;
}

export default new FilmatchBE();
