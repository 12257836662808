import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Autocomplete, Box, Button,
    Chip, CircularProgress, Divider,
    Grid,
    IconButton, Slider, Stack,
    TextField,
    Typography
} from "@mui/material";
import {DatePicker, LoadingButton} from "@mui/lab";
import React, {useEffect, useState} from "react";
import {Category} from "../models/Category";
import filmatchBE from "../service/FilmatchBE";
import {notifyError} from "../utils/notification";
import {useNotificationActions} from "../hooks/useNotification";
import {ArrowDownwardOutlined} from "@mui/icons-material";
import {WatchProvider} from "../models/WatchProviders";
import {useSession, useSessionAction} from "../hooks/useSession";
import {useNavigate} from "react-router-dom";
import {Navbar} from "../components/navbar/navbar";
import {Colors} from "../utils/theme";
import {UserPublicInfo} from "../models/User";
import {AvailableRegion} from "../models/AvailableRegion";

export const CreatePartyPage: React.FC = () => {
    const session = useSession();
    const { setSession } = useSessionAction();
    const navigator = useNavigate();
    const currentYear = new Date().getFullYear();
    const regex = new RegExp("^(19[2-9]\\d|20\\d{2}|" + currentYear + ")$");
    const [loadingParty,setLoadingParty] = useState<boolean>(false);
    const [numberMovies,setNumberMovies] = useState<number>(5);
    const [categoryList, setCategoryList] = useState<Category[]>([]);
    const [availableRegions, setAvailableRegions] = useState<AvailableRegion[]>([]);
    const [nameParty, setNameParty] = useState<string>('');
    const [errorNameParty, setErrorNameParty] = useState<{error:boolean, message?:string }>({error:false, message:undefined});
    const [fromYear, setFromYear] = useState<string>('');
    const [fromYearError, setFromYearError] = useState<{error:boolean, message?:string }>({error:false, message:undefined});
    const [toYearError, setToYearError] = useState<{error:boolean, message?:string }>({error:false, message:undefined});
    const [toYear, setToYear] = useState<string>('');
    const [errorCountry, setErrorCountry] = useState<{error:boolean,message?:string}>({error:false});
    const [contry, setCountry] = useState<string|null>(null);
    const [watchProviders, setWatchProviders] = useState<WatchProvider[]>([]);
    const [loadingWatchProvider, setLoadingWatchProvider] = useState<boolean>(false);
    const [loadingCategory, setLoadingCategory] = useState<boolean>(false);
    const [profile,setProfile] = useState<UserPublicInfo | undefined>();
    const { addNotification } = useNotificationActions();
    useEffect(() => {
        setLoadingCategory(true);
        filmatchBE.getCategories().then(setCategoryList)
            .catch((e:any) => {
                if(e.res.status === 401){
                    notifyError('Forbidden Request', addNotification)
                    navigator('/signin')
                } else {
                    notifyError('Errore nel reperimento categorie', addNotification)
                }
            });
        (async () => {
            try {
                const profile = await filmatchBE.getProfile();
                setProfile(profile);
            } catch (e:any) {
                if(e.res.status === 401){
                    notifyError('Forbidden Request', addNotification)
                    setSession(null);
                    navigator('/signin')
                } else {
                    notifyError('Errore on loading Profile', addNotification)
                }
            }
        })();
    }, []);
    useEffect(() => {
        filmatchBE.getAvailableRegions().then((regions) => {
            setAvailableRegions(regions);
        }).catch((e) => {
            notifyError('Error loading available regions', addNotification);
        });
    }, []);

    useEffect(() => {
        const cat = categoryList.map((cl) => {
            if(profile?.categories?.find((c) => c.id===cl.id)){
                cl.selected=true;
            }
            return cl;
        })
        setCategoryList(cat);
        setLoadingCategory(false);
        const wp = watchProviders.map((wl) => {
            if(profile?.watchProviders?.find((w) => w.provider_id===wl.provider_id)){
                wl.selected=true;
            }
            return wl;
        })
        setWatchProviders(wp);
        setLoadingWatchProvider(false);
    }, [profile]);

    useEffect(() => {
        if(nameParty.trim().length > 0){
            setErrorNameParty({error:false, message:undefined});
        }
    }, [nameParty]);

    const handleChangeNumberMovies = (event: Event, newValue: number | number[]) => {
        setNumberMovies(newValue as number);
    }

    useEffect(() => {
        if(fromYear.trim().length > 0 && !regex.test(fromYear)){
            setFromYearError({error:true, message:'From Year is not valid'})
        }
        else {
            setFromYearError({error:false, message:undefined})
        }
        if(toYear.trim().length > 0 && !regex.test(toYear)){
            setToYearError({error:true, message:'To Year is not valid'})
        } else {
            setToYearError({error:false, message:undefined})
        }
    }, [fromYear,toYear]);

    const createParty = async () => {
        if(errorCountry.error){
            notifyError('Country is required', addNotification);
            return;
        }
        if(nameParty.trim().length <= 0 || watchProviders.filter((wp) => wp.selected).length <= 0){
            if(nameParty.trim().length <= 0){
                setErrorNameParty({error:true, message:'Name Party is required'});
            } else {
                setErrorNameParty({error:false, message:undefined});
            }
            if(watchProviders.filter((wp) => wp.selected).length <= 0){
                notifyError('Select at least one streaming platform', addNotification);
            }
            return;
        }
        if(fromYear.trim().length && toYear.trim().length){
            if(parseInt(toYear)<parseInt(fromYear)){
                notifyError('From Year must be a date before To Year', addNotification);
                return
            }
        }
        setLoadingParty(true);
        try {
            const party = await filmatchBE.createParty({
                partyName: nameParty.trim(),
                organizer: session?.user.username ?? '',
                numberMovies: numberMovies,
                otherInformation: {
                    'release_date.gte': fromYear,
                    'release_date.lte': toYear,
                    language: `${contry?.toLowerCase()}-${contry?.toUpperCase()}`,
                    watch_region: contry ?? 'US',
                    with_genres: (categoryList.filter((c) => c.selected).map((c) => c.id)).join("|"),
                    with_watch_providers: watchProviders.filter((c) => c.selected).map((c) => c.provider_id).join("|")
                }
            });
            navigator(`/party/${party.codeParty}`);
        } catch (e: any) {
            if(e.res?.status === 401){
                notifyError('Forbidden Request', addNotification)
                navigator('/signin')
            } else {
                notifyError('Error on creating party', addNotification);
            }
        }
    }

    const handleCountryChange = (country?:string) => {
        setCountry(country ?? null);
        setErrorCountry({error:!errorCountry.error})
        if(!country){
            setErrorCountry({error:true,message:'Country is required'});
        } else {
            setErrorCountry({error:false,message:undefined});
        }
        setLoadingWatchProvider(true);
        filmatchBE.getWatchProviders(country ?? 'US').then((watchProviders) => {
            setWatchProviders(watchProviders.sort((a,b) => a.display_priority - b.display_priority))
            setLoadingWatchProvider(false);
        })
            .catch((e:any) => {
                if(e.res?.status === 401){
                    notifyError('Forbidden Request', addNotification)
                    setSession(null);
                    navigator('/signin')
                } else {
                    notifyError('Errore nel reperimento delle piattaform Streaming', addNotification)
                }
            });
    }

    const selectCategory = (category: Category) => {
        category.selected = !category.selected;
        setCategoryList(categoryList.map((c) => {
            if (c.id === category.id) {
                c.selected = category.selected;
            }
            return c;
        }));
    }

    const selectWatchProvider = (watchProvider: WatchProvider) => {
        watchProvider.selected = !watchProvider.selected;
        setWatchProviders(watchProviders.map((c) => {
            if (c.provider_id === watchProvider.provider_id) {
                c.selected = watchProvider.selected;
            }
            return c;
        }));
    }

    return (
        <Grid
            container
            component="form"
            spacing={2}
            direction="column"
            alignItems="center"
            justifyContent="start"
            mt={6}
            mb={10}
        >
            <Grid item>
                <Typography component="h1" variant="h5">
                    Create Your Party
                </Typography>
            </Grid>
            <Grid item>
                <TextField id="outlined-basic"
                           sx={{width:260}}
                           onChange={(e) => setNameParty(e.target.value)}
                           value={nameParty}
                           label="Name Party"
                           error={errorNameParty.error}
                           helperText={errorNameParty.message}
                           variant="outlined" />
            </Grid>
            <Grid item>
                <Stack direction="row" justifyContent="start" alignItems="center">
                    <TextField id="outlined-basic"
                               label="From year"
                               sx={{width:100}}
                               onChange={(e) => setFromYear(e.target.value)}
                               value={fromYear}
                               error={fromYearError.error}
                               helperText={fromYearError.message}
                               variant="outlined" />
                    <Divider sx={{borderBottomWidth:5,m:1, width:40}}/>
                    <TextField id="outlined-basic"
                               label="To year"
                               sx={{width:100}}
                               onChange={(e) => setToYear(e.target.value)}
                               value={toYear}
                               error={toYearError.error}
                               helperText={toYearError.message}
                               variant="outlined" />
                </Stack>
            </Grid>
            <Grid item>
            </Grid>
            <Grid item width={200}>
                <Typography>Number of Movies</Typography>
                <Slider sx={{mt:3}} defaultValue={5} min={1} max={20} onChange={handleChangeNumberMovies}  aria-label="Number Movies" valueLabelDisplay="on" />
            </Grid>
            <Grid item>
                <Autocomplete
                    id="country-select"
                    sx={{ width: 250 }}
                    options={availableRegions}
                    isOptionEqualToValue={(option, value) => option.english_name === value.english_name}
                    autoHighlight
                    onChange={(event, newValue) => {handleCountryChange(newValue?.iso_3166_1)}}
                    getOptionLabel={(option) => option.english_name}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            <img
                                loading="lazy"
                                width="20"
                                srcSet={`https://flagcdn.com/w40/${option.iso_3166_1.toLowerCase()}.png 2x`}
                                src={`https://flagcdn.com/w20/${option.iso_3166_1.toLowerCase()}.png`}
                                alt=""
                            />
                            {option.english_name} ({option.iso_3166_1})
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Choose a country"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item>
                <Accordion sx={{maxWidth:300}}>
                    <AccordionSummary
                    expandIcon={<ArrowDownwardOutlined/>}
                    >
                     <Typography>Category</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{marginTop:2,overflowY:'auto', maxHeight:200}}>
                        {!loadingCategory ? categoryList.map((category) => {
                            return (
                                <>
                                    {category.selected ? <Chip sx={{m:0.3, backgroundColor:'#831010'}} label={category.name} variant='filled' onClick={() => selectCategory(category)}/>
                                        :
                                        <Chip sx={{m:0.3}} label={category.name} variant='outlined' onClick={() => selectCategory(category)}/>
                                    }
                                </>
                            )
                        }) : <CircularProgress/>}
                    </AccordionDetails>
                </Accordion>
            </Grid>
            {contry ?
                <Grid item>
                    <Accordion sx={{maxWidth:300}}>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardOutlined/>}
                        >
                            <Typography>Streaming Platform</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{marginTop:2,overflowY:'auto', maxHeight:200}}>
                            {!loadingWatchProvider ? watchProviders.map((watchProvider) => {
                                return (
                                    <>
                                        {watchProvider.selected ? <Chip sx={{m:0.3, backgroundColor:'#831010'}} label={watchProvider.provider_name} variant='filled' onClick={() => selectWatchProvider(watchProvider)}/>
                                            :
                                            <Chip sx={{m:0.3}} label={watchProvider.provider_name} variant='outlined' onClick={() => selectWatchProvider(watchProvider)}/>
                                        }
                                    </>
                                )
                            }) : <CircularProgress/>}
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                :
                null}
            <Grid item>
                <LoadingButton loading={loadingParty} variant="contained" onClick={createParty}>
                    Create Party
                </LoadingButton>
            </Grid>
        </Grid>
    )
}
