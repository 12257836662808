import React from "react";
import {Box, Card, CardContent, CardMedia, Chip, IconButton, Skeleton, Stack, Typography} from "@mui/material";

export const SkeletonMovieCard: React.FC = () => {
    return (
        <Card sx={{ width: 345, height:820}}>
            <Skeleton variant="rectangular" width={345} height={450} animation="wave"/>
            <CardContent>
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} />
                <Box height={160} mt={5}>
                    <Skeleton variant="text" sx={{ fontSize: '0.8rem', width:250 }} />
                    <Skeleton variant="text" sx={{ fontSize: '0.8rem', width:270 }} />
                    <Skeleton variant="text" sx={{ fontSize: '0.8rem', width:250 }} />
                    <Skeleton variant="text" sx={{ fontSize: '0.8rem', width:250 }} />
                    <Skeleton variant="text" sx={{ fontSize: '0.8rem', width:270 }} />
                    <Skeleton variant="text" sx={{ fontSize: '0.8rem', width:250 }} />
                </Box>
            </CardContent>
            <Stack direction="row" justifyContent="space-around" alignItems="center" marginBottom={3}>
                <Skeleton variant="circular" width={40} height={40} />
                <Skeleton variant="circular" width={40} height={40} />
                <Skeleton variant="circular" width={40} height={40} />
            </Stack>
        </Card>
    )
}
