import apiCalls from "../utils/fetch";
import {UserPublicInfo} from "../models/User";
import {Category} from "../models/Category";
import {WatchProvider} from "../models/WatchProviders";

export const getProfile = (): any => apiCalls.get<UserPublicInfo>({
    endpoint: 'user/getprofile',
    headers: {
        'Content-Type': 'application/json',
    },
});

export const updateCategories = (categories: Category[]): any => apiCalls.post<void>({
    endpoint: 'user/updatePreferences/category',
    headers: {
        'Content-Type': 'application/json',
    },
    data: JSON.stringify([ ...categories ]),
})

export const updateWatchProvider = (watchProviders: WatchProvider[]): any => apiCalls.post<void>({
    endpoint: 'user/updatePreferences/watchProviders',
    headers: {
        'Content-Type': 'application/json',
    },
    data: JSON.stringify([ ...watchProviders ]),
})
