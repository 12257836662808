import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { getSessionToken, getSessionUser } from '../../utils/storage';
import {UserPublicInfo} from "../../models/User";
import {useNavigate} from "react-router-dom";

export type Session = {
    user: UserPublicInfo;
    token: string;
};

export const SessionActionsContext = React.createContext<{
    setSession:(session: Session | null) => void;
}>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setSession: () => {
    },
});

const user = getSessionUser();
const token = getSessionToken();

export const SessionContext = React.createContext<Session | null>(null);

export const SessionProvider = ({ children }: { children: React.ReactNode }) => {
    const navigator = useNavigate();
    const [session, setSessionState] = useState<Session | null>(
        user && token
            ? {
                user,
                token,
            }
            : null,
    );

    const setSession = useCallback((s: Session | null) => setSessionState(s), []);

    const actions = useMemo(
        () => ({
            setSession,
        }),
        [setSession],
    );

    return (
        <SessionContext.Provider value={session}>
            <SessionActionsContext.Provider value={actions}>{children}</SessionActionsContext.Provider>
        </SessionContext.Provider>
    );
};
