import {Box, Button, Grid} from "@mui/material";
import React, {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {ListResultMovie} from "../components/listResultMovie/listResultMovie";
import filmatchBE from "../service/FilmatchBE";
import {notifyError} from "../utils/notification";
import {PartyPublicInfo} from "../models/PartyPublicInfo";
import {useSession, useSessionAction} from "../hooks/useSession";
import {useNotificationActions} from "../hooks/useNotification";

export const PartyResultPage: React.FC = () => {
    const { partyId} = useParams();
    const navigator = useNavigate();
    const setSession = useSessionAction();
    const { addNotification } = useNotificationActions();
    const [party,setParty] = React.useState<PartyPublicInfo>();

    useEffect(() => {
        (async () => {
            try {
                if(partyId){
                    const partyResult = await filmatchBE.getParty(partyId);
                    setParty(partyResult);
                }

            } catch (e:any) {
                if(e.res.status === 401){
                    notifyError('Forbidden Request', addNotification)
                    setSession(null);
                    navigator('/signin')
                } else {
                    notifyError('Errore nel caricamento del party', addNotification)
                }
            }
        })();
    }, []);

    return (
        <Box mt={1}>
            <Button variant='text' startIcon={<ArrowBackIosIcon/>} sx={{mb:2,ml:2}} onClick={() => {navigator(`/party/${partyId}`)}}> Party </Button>
            <Grid
                container
                component="div"
                direction="column"
                alignItems="center"
                justifyContent="start"
                mb={6}
            >
                {partyId ? <ListResultMovie watchRegion={party?.otherInformation.watch_region ?? ''} withPosition={true} partyId={partyId}/> : null}

            </Grid>
        </Box>

    )
}
