import { FetchError } from './fetch';
import { Notification } from '../components/notification/notificationContext';
import { isUnauthorized } from './authentication';

export const notifyError = (
    error: unknown | string,
    addNotification: (notification: Notification) => void,
): void => {
    if (error instanceof FetchError) {
        if (isUnauthorized(error)) {
            addNotification({
                message: 'Chiamata non autorizzata. Effettua un nuovo signin',
                type: 'error',
            });
        } else {
            addNotification({
                message: error.errorBody.message ?? error.res.statusText,
                type: 'error',
            });
        }
    } else if (error instanceof Error) {
        addNotification({
            message: error?.message ?? 'Errore di sistema',
            type: 'error',
        });
    } else if (typeof error === 'string') {
        addNotification({
            message: error,
            type: 'error',
        });
    } else {
        addNotification({
            message: 'Errore di sistema',
            type: 'error',
        });
    }
};
