import axios from 'axios';

type Method = 'GET' | 'POST';

type ResponseType = 'json' | 'blob';

type BaseParams = {
    endpoint: string;
    queryParams?: URLSearchParams;
    useJson?: boolean;
    headers?: {
        [key: string]: string;
    };
};

type Params = {
    GET: BaseParams;
    POST: BaseParams & { data: RequestInit['body'] };
};

interface ErrorBody {
    error: string;
    message?: string | string[];
    statusCode: number;
}

function isPost(method: Method): method is 'POST' {
    return method === 'POST';
}

function isBlob(responseType?: ResponseType): responseType is 'blob' {
    return responseType === 'blob';
}

export function getBearerToken(): string {
    const token = window.localStorage.getItem('token');
    return `Bearer ${token}`;
}

function buildEndpoint(relativePath: string, queryParams?: URLSearchParams): string {
    const baseUrl = process.env.REACT_APP_BACKEND_URL;
    let url = `${baseUrl}/${relativePath}`;
    if (queryParams) {
        url = `${url}?${queryParams}`;
    }
    return url;
}

export class FetchError {
    constructor(
        public message: string,
        public res: Pick<Response, 'status' | 'statusText' | 'type' | 'url'>,
        public errorBody: ErrorBody,
    ) {
    }
}

const createCallDownaload = <T extends Method>({ method }: { method: T }) => async (
    params: Params[T],
    // eslint-disable-next-line require-await
): Promise<any> => {
    const { endpoint, queryParams } = params;
    if (method === 'POST') {
        return axios({
            url: buildEndpoint(endpoint, queryParams),
            method: 'POST',
            responseType: 'blob',
            headers: { Authorization: getBearerToken(), 'C-Rbb-Friend': 'Mellon' },
        });
    }
    return axios({
        url: buildEndpoint(endpoint, queryParams),
        method: 'GET',
        responseType: 'blob',
        headers: { Authorization: getBearerToken(), 'C-Rbb-Friend': 'Mellon' },
    });
};

const createCall = <T extends Method>({ method }: { method: T }) => async <K, R extends ResponseType = 'json'>(
    params: Params[T],
    responseType?: R,
): Promise<{
    data: R extends 'blob' ? Blob : K;
    headers: Headers;
}> => {
    const { endpoint, queryParams, headers } = params;
    const reqHeaders = headers ?? {};
    const token = window.localStorage.getItem('token');
    if (token) {
        reqHeaders.Authorization = getBearerToken();
    }
    const init: { method: string; headers: any; body?: any } = {
        method,
        headers: reqHeaders,
    };
    if (isPost(method)) {
        init.body = (params as Params[typeof method]).data;
    }
    const res = await fetch(buildEndpoint(endpoint, queryParams), init).then((response) => response).catch((e) => {
        throw new FetchError('Request Failed', {
            status: res.status,
            statusText: res.statusText,
            type: res.type,
            url: res.url,
        }, e);
    });
    const resHeaders = res.headers;
    if (!res.ok) {
        const errorBody = (await res.json()) as ErrorBody;
        throw new FetchError('Request Failed', {
            status: res.status,
            statusText: res.statusText,
            type: res.type,
            url: res.url,
        }, errorBody);
    }
    if (isBlob(responseType)) {
        return {
            data: (await res.blob()) as any,
            headers: resHeaders,
        };
    }
    const contentLength = parseInt(res.headers.get('Content-Length') ?? '0', 10);
    if (contentLength) {
        return {
            data: await res.json(),
            headers: resHeaders,
        };
    }
    return {} as any;
};

const createCallWithResponseType = <T extends Method>({ method }: {
    method: T
}) => async <K, R extends ResponseType = 'json'>(
    params: Params[T],
    responseType?: R,
): Promise<{
    data: Response | K;
    headers: Headers;
}> => {
    const {
        endpoint, queryParams, headers, useJson,
    } = params;
    const reqHeaders = headers ?? {};
    const token = window.localStorage.getItem('token');
    if (token) {
        reqHeaders.Authorization = getBearerToken();
    }
    reqHeaders['C-Rbb-Friend'] = 'Mellon';
    const init: { method: string; headers: any; body?: any } = {
        method,
        headers: reqHeaders,
    };
    if (isPost(method)) {
        init.body = (params as Params[typeof method]).data;
    }
    const res = await fetch(buildEndpoint(endpoint, queryParams), init).then((response) => response).catch((e) => {
        throw new FetchError('Request Failed', {
            status: res.status,
            statusText: res.statusText,
            type: res.type,
            url: res.url,
        }, e);
    });
    const resHeaders = res.headers;
    if (!res.ok) {
        const errorBody = (await res.json()) as ErrorBody;
        throw new FetchError('Request Failed', {
            status: res.status,
            statusText: res.statusText,
            type: res.type,
            url: res.url,
        }, errorBody);
    }
    if (isBlob(responseType)) {
        return {
            data: (await res.blob()) as any,
            headers: resHeaders,
        };
    }
    const contentLength = parseInt(res.headers.get('Content-Length') ?? '0', 10);
    if (contentLength) {
        if (useJson) {
            return {
                data: await res.json(),
                headers: resHeaders,
            };
        }
        return {
            data: res,
            headers: resHeaders,
        };
    }
    return {} as any;
};

export function getContentDispositionFileName(headers: Headers, fallback: string): string {
    if (headers.has('Content-Disposition')) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const contentDisposition = headers.get('Content-Disposition')!;
        const [, fileName] = contentDisposition.split('filename=');
        if (fileName?.length) {
            return fileName;
        }
    }
    return fallback;
}

export default {
    getWithResponseType: createCallWithResponseType({ method: 'GET' }),
    get: createCall({ method: 'GET' }),
    post: createCall({ method: 'POST' }),
    put: createCall({ method: 'POST' }),
    postDownload: createCallDownaload({ method: 'POST' }),
};
